import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigStore } from '../../store/config.store';
import { AppReadyEvent } from '../../events';

declare var $: any;

/**
 * Auth Component
 */
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  public invitationId: number;
  public errorMsg: string;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private configStore: ConfigStore,
    private appReadyEvent: AppReadyEvent) {
    this.smartbanner();
  }

  /**
   * Init
   * @inheritDoc
   */
  ngOnInit(): any {
    this.route.params.subscribe((params: any) => {
      this.invitationId = +params['id'] || 0;
      this.configStore.setIdUrl(this.invitationId);

      this.errorMsg = params['errorMsg'] || '';
      this.configStore.setErrorMsg(this.errorMsg);
    });
    this.appReadyEvent.trigger();
  }

  /**
   * TODO To be completed
   */
  smartbanner(): any {
    $(function (): any {
      $.smartbanner({
        title: 'ORTIF App',
        author: 'ORTIF',
        inAppStore: 'Sur l\'App Store',
        inGooglePlay: 'Sur le Play Store',
        price: 'Gratuit',
        // defaults to website icon image
        icon: 'assets/images/ortif-app.png',
        appStoreLanguage: 'fr',
        button: 'OUVRIR',
        daysHidden: 0,
        daysReminder: 0,
        // layer: true,
        // iOSUniversalApp: false => If the iOS App is a universal app for both iPad and iPhone,
        // display Smart Banner to iPad users, too.
      });
    });

  }

  /**
   * TODO To be completed
   * @param mail
   */
  onRegister(mail: string): void {
    this.router.navigate(['registration'], { queryParams: { mail: mail } });
  }
}
