/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./header.component";
import * as i5 from "../../../store/config.store";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["id", "logoContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "ORTIF"], ["src", "./assets/images/logo_ORTIF.png"], ["width", "254"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "a", [["id", "tooltipContainer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["alt", "Aide"], ["height", "51"], ["src", "./assets/images/pictos/aide.png"], ["width", "51"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["id", "tooltip"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "first"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["href", "https://formation.sesan.fr/ortif/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["D\u00E9couvrir ORTIF"])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Aide \u00E0 la connexion"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 4, 0, "/"); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵinlineInterpolate(1, "", (_co.helpConnect || ""), ""); _ck(_v, 13, 0, currVal_3); }); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 376832, null, 0, i4.HeaderComponent, [i5.ConfigStore], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i4.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
