import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {OrtifLayoutComponent} from './_layouts/ortif-layout/ortif-layout.component';
import {AcetiamLayoutComponent} from './_layouts/acetiam-layout/acetiam-layout.component';
import {AuthComponent} from './pages/auth/auth.component';

import {LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings} from 'localize-router';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';

import {LocalizeRouterHttpLoader} from 'localize-router-http-loader';
import {ErrorComponent} from './pages/error/error.component';
import {ProtectedRegistrationComponent} from './pages/auth/protected-registration/protected-registration.component';

export const routes: Routes = [
  { path: '', redirectTo: '/index', pathMatch: 'full' },
  { path: 'error', component: ErrorComponent },

  {
    path: 'index',
    component: OrtifLayoutComponent,
    children: [
      { path: '', component: AuthComponent },
      { path: ':id', component: AuthComponent },
      { path: 'error/:errorMsg', component: AuthComponent }
    ]
  },

  {
    path: 'inscription-protegee',
    component: OrtifLayoutComponent,
    children: [
      { path: '', component: ProtectedRegistrationComponent }
    ]
  },

  {
    path: 'invite/:idInvitation',
    component: AcetiamLayoutComponent,
  },

  { path: '**', redirectTo: '/index' }

];

/**
 * TODO To be completed
 * @param translate
 * @param location
 * @param settings
 * @param http
 */
export function localizeRouterParserFactory(translate: any, location: any, settings: any, http: any): any {
  return new LocalizeRouterHttpLoader(translate, location, settings, http, './assets/i18n/routes.json');
}

/**
 * TODO To be completed
 */
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {useHash: true}),  // .../#/your-route/
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: localizeRouterParserFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
      }
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
