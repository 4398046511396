import {Component, OnInit, Renderer2} from '@angular/core';
import { AppReadyEvent } from '../../events';
import {Meta} from '@angular/platform-browser';

/**
 * ACETIAM layout
 */
@Component({
  selector: 'app-acetiam-layout',
  templateUrl: './acetiam-layout.component.html',
  styleUrls: ['./acetiam-layout.component.scss']
})
export class AcetiamLayoutComponent implements OnInit {

  constructor(
      private appReadyEvent: AppReadyEvent,
      private renderer: Renderer2,
      private meta: Meta
  ) { }

  /**
   * To be completed
   */
  ngOnInit(): void {
    this.renderer.setAttribute(document.body, 'class', 'acetiam');
    this.appReadyEvent.trigger();
    this.meta.addTags([
      { name: 'description', content: 'ACETIAM' },
      // { name: 'apple-itunes-app', content: 'app-id=1255452178' },
      // { name: 'google-play-app', content: 'app-id=com.parsys.ortif' },
    ]);
  }

}
