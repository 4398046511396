import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JWT } from 'npm-jwt/index.umd';
import { LOGGER } from 'npm-module-logger/index.umd';

import { environment } from '../../../../environments/environment';
import { User } from '../../../models/user/user.model';
import { LoginService } from '../../../services/login/login.service';
import { UtilsService } from '../../../services/utils';
import { ConfigStore } from '../../../store/config.store';
import * as Constants from '../../commons/constants';

/**
 * Login Component, provide service to authenticate a user
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user: User;
  formSubmitted: boolean = false;
  loginError: boolean = false;
  emailEmpty: boolean = false;
  resetPasswordError: boolean = false;
  // Boolean to know if user try to submit his email
  emailSubmitted: boolean = false;
  forgottenPassword: boolean = false;
  emailSent: boolean = false;
  enrsKo: boolean = false;
  idsKo: boolean = false;
  generalError: boolean = false;
  spinn: boolean = false;
  noRights: boolean = false;
  forgottenPasswordForm: FormGroup;

  constructor(
    private loginService: LoginService,
    private configStore: ConfigStore,
    private formBuilder: FormBuilder,
    private utilsService: UtilsService
  ) {

    this.user = new User;
  }

  /**
   * Inherit
   * @inheritDoc
   */
  ngOnInit(): void {

    this.forgottenPasswordForm = this.formBuilder.group({
      emailControl: ['', Validators.required]
    });

    if (this.configStore.getErrorMsg !== '' && this.configStore.getErrorMsg === 'noRights') {
      this.noRights = true;
    }

  }

  /**
   * Check if email input is valid.
   * @param email
   */
  checkEmailAddress(email: string): void {
    this.emailEmpty = !email || (email.length === 0);
    this.emailSubmitted = true;

    if (this.emailEmpty) {
      this.enrsKo = false;
      this.generalError = false;
      return;
    }
    this.resetPasswordError = false;
    this.spinn = true;
    this.loginService.getResetPassword(email).subscribe(
      (data: any) => this.successSentMail(data),
      (error: any) => this.redirectAfterKo(error),
      () => { this.spinn = false; }
    );
  }

  /**
   * fake login error for the redirection
   */
  login(identifier: any, password: any): void {
    this.formSubmitted = true;
    this.loginError = false;
    this.noRights = false;
    if (identifier.valid && password.valid === true) {
      this.spinn = true;
      this.checkLoginFromIdentityAccessManagerAndEnrs();
    }
  }


  /**
   * Check if login exist on identity and access manager (IDSphere), if exist, check on ENRS, else display error message
   */
  checkLoginFromIdentityAccessManagerAndEnrs(): void {

    this.loginService.postLogin(JSON.stringify(this.user)).subscribe((data: any) => {
      this.successLogin(data);
    },
      (error: any) => {
        LOGGER.info('error post', error);

        this.redirectAfterKo(error);
        LOGGER.info('Error to search user from Ortif');
      }
    );
  }
  /**
   * Set properties that indicates mail send in success
   * @param data
   */
  successSentMail(data: any): void {
    this.generalError = false;
    this.emailSent = true;
  }

  /**
   * Connection OK
   * @param data
   */
  successLogin(data: any): void {
    this.generalError = false;


    if (data.body.hasOwnProperty('enrs_ko') && data.body.enrs_ko) {
      if (data.body.hasOwnProperty('location') && data.body.location !== '') {
        this.utilsService.assignUrl(data.body.location);
      } else {
        this.spinn = false;
        LOGGER.error('ENRS KO => no redirect url found');
      }
    } else {
      const bearer = data.headers.get('Authorization');
      const token = bearer.substr(7);
      const decodedToken = JWT.decode(token);
      if (JWT.validate(token) && decodedToken.hasOwnProperty('userName')) {
        // In prod all front are on the same domain so localStorage works but in dev front are in different
        // domain so we passe the token in the url
        localStorage.setItem(Constants.SESSION_TOKEN, token);
        // search user from IAM
        this.loginService.searchUserFromIdentityAccessManager({ login: this.user.userName }, bearer).subscribe((users: any) => {
          if (users.nombreUtilisateurs === 0) {
            this.spinn = false;
            this.idsKo = true;
            LOGGER.info('Error missing user from Ortif');
            return;
          }
          if (environment.production) {
            this.utilsService.assignUrl(this.configStore.getFrontDashboard);
          } else {
            this.utilsService.assignUrl(this.configStore.getFrontDashboard + '?jwt=' + token);
          }
        });
      } else {
        LOGGER.error('token invalid', token);
      }
    }
  }


  /**
   * Redirection for the "degraded connection"
   * @param data
   */
  redirectAfterKo(data: any): void {
    this.spinn = false;
    if (data.error.internCode === '2202') {
      this.resetPasswordError = true;
    } else if (data.error.rights === false) {
      this.noRights = true;
    } else if (data.error.enrs_ko) {
      this.generalError = true;
      this.utilsService.assignUrl(data.error.location);
      this.enrsKo = true;
    } else {
      this.generalError = true;
      this.loginError = true;
    }
  }

  /**
 * called on form submit
 */
  onForgotPasswordSubmit(): void {
    console.log('SUBMIT', this.forgottenPasswordForm);

    this.checkEmailAddress(this.forgottenPasswordForm.value.emailControl);
  }

  /**
  * TODO
  */
  loadForgotPassword(): void {
    this.forgottenPassword = true;
  }

  /**
  * TODO
  */
  backFromForgotPassword(): void {
    this.emailSubmitted = false;
    this.resetPasswordError = false;
    this.enrsKo = false;
    this.generalError = false;

    this.forgottenPassword = !this.forgottenPassword;
  }

}
