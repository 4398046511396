import {Component, DoCheck, OnInit} from '@angular/core';
import {ConfigStore} from '../../../store/config.store';

/**
 * Header Componenet
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, DoCheck {

  invitationId: number;

  public helpConnect: string;

  constructor(private configStore: ConfigStore) {
  }

  /**
   * TODO To be completed
   */
  ngOnInit(): void {
    this.removeSngCookie();
    this.helpConnect = this.configStore.allUrlByStatic$.helpConnect;
  }

  /**
   * TODO To be completed
   */
  ngDoCheck(): void {
    if (this.configStore.getIdUrl !== this.invitationId) {
      this.invitationId = this.configStore.getIdUrl;
    }
  }


  /**
   * Remove Sng cookie
   */
  removeSngCookie(): void {
    const host = window.location.hostname;
    const domain = host.substr(host.indexOf('.') + 1);

    // Delete the cookie
    document.cookie = 'sngcookie=;'
      + 'path=/;'
      + 'domain=' + domain + ';'
      + 'expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
}
