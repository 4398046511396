import { Component, OnInit } from '@angular/core';
import { JWT } from 'npm-jwt/index.umd';

import { environment } from '../../../../environments/environment';
import { User } from '../../../models/user/user.model';
import { Api } from '../../../services/api/api.service';
import { LoginService } from '../../../services/login/login.service';
import { UtilsService } from '../../../services/utils';
import { ConfigStore } from '../../../store/config.store';
import * as Constants from '../../commons/constants';

/**
 * Invitation Component
 */
@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {

  user: User;
  invitationId: number;
  loginOk: boolean = true;
  errorId: boolean = false;
  loginError: boolean = false;
  invitationExpired: boolean = false;
  generalError: boolean = false;
  spinn: boolean = false;


  constructor(
    private loginService: LoginService,
    private api: Api,
    private configStore: ConfigStore,
    private utilsService: UtilsService) {

    this.user = new User;
    this.user.type = 'guest';
  }

  /**
   * Iinit
   * @inheritDoc
   */
  ngOnInit(): void {
    this.configStore.getBackDirectoryPromise.then(() => {
      if (this.configStore.getIdUrl !== this.invitationId && this.configStore.getIdUrl !== 0) {
        this.invitationId = this.configStore.getIdUrl;
        this.api.getDirectory('/exceptional/' + this.invitationId).subscribe(
          (data: any) => this.getinfoUser(data),
          () => this.errorId = true,
          () => console.log('complete')
        );
      }
    });
  }

  /**
   * Check user info of guest in Directory and if the invitation date is not expired
   */
  getinfoUser(data: object): void {
    const nowDate = new Date();
    const beforeDate = new Date(data['body'].notAfter);

    if (nowDate.getTime() > beforeDate.getTime()) {
      this.invitationExpired = true;
    } else {
      this.loginOk = true;
      this.api.getDirectory('/guest/' + data['body'].beneficiaryId).subscribe(
        (response: any) => {
          this.user.userName = response['body'].uid;
        },
        (error: any) => console.log('error', error)
      );
    }

  }

  /**
   * Fake login error for the redirection
   */
  login(): void {
    this.loginError = false;
    this.spinn = true;

    this.loginService.postLogin(JSON.stringify(this.user)).subscribe(
      (data: any) => this.successLogin(data),
      (error: any) => this.redirectAfterKo(error),
      () => {
        this.spinn = false;
      }
    );

  }

  /**
   * Connection OK
   * @param data
   */
  successLogin(data: any): void {
    this.generalError = false;

    if (data.body.hasOwnProperty('enrs_ko') && data.body.enrs_ko) {
      if (data.body.hasOwnProperty('location') && data.body.location !== '') {
        this.utilsService.assignUrl(data.body.location);
      } else {
        console.error('ENRS KO => no redirect url found');
      }
    } else {
      const bearer = data.headers.get('Authorization');
      const token = bearer.substr(7);
      const decodedToken = JWT.decode(token);
      if (JWT.validate(token) && decodedToken.hasOwnProperty('userName')) {
        // In prod all front are on the same domain so localStorage works but in dev front are in different
        // domain so we passe the token in the url
        localStorage.setItem(Constants.SESSION_TOKEN, token);
        if (environment.production) {
          this.utilsService.assignUrl(this.configStore.getFrontDashboard);
        } else {
          this.utilsService.assignUrl(this.configStore.getFrontDashboard + '?jwt=' + token);
        }
      } else {
        console.error('token invalid', token);
      }
    }
  }

  /**
   * Redirection for the "degraded connection"
   * @param error
   */
  redirectAfterKo(error: object): void {
    console.log('error connection login : ', error);
    this.loginError = true;
    this.spinn = false;
  }

}
