import {Component, OnInit} from '@angular/core';
import { ConfigStore } from '../../store/config.store';

/**
 * Cps component
 */
@Component({
  selector: 'app-external-link',
  templateUrl: './external-link.component.html',
  styleUrls: ['./external-link.component.scss']
})
export class ExternalLinkComponent implements OnInit {

  tvhLink: string;

  constructor(
    private configStore: ConfigStore
  ) {
  }

  /**
   * Init
   * @inheritDoc
   */
  ngOnInit(): void {
    this.tvhLink = this.configStore.allUrlByStatic$.tvhLink;
  }

}
