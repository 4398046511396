/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ortif-footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./ortif-footer.component";
var styles_OrtifFooterComponent = [i0.styles];
var RenderType_OrtifFooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrtifFooterComponent, data: {} });
export { RenderType_OrtifFooterComponent as RenderType_OrtifFooterComponent };
export function View_OrtifFooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "row"], ["id", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "col col-12 col-sm-4 first"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "ARS"], ["src", "./assets/images/logoARS.png"], ["width", "130"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col col-12 col-sm-4 second"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["href", "https://news-portail.ortif.sante-idf.fr/mentions-legales/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Mentions l\u00E9gales"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "col col-12 col-sm-4 third"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["alt", "GIP SESAN"], ["src", "./assets/images/logo_SESAN_logo_SESAN_couleurs.png"], ["width", "169"]], null, null, null, null, null))], null, null); }
export function View_OrtifFooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ortif-footer", [], null, null, null, View_OrtifFooterComponent_0, RenderType_OrtifFooterComponent)), i1.ɵdid(1, 114688, null, 0, i2.OrtifFooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrtifFooterComponentNgFactory = i1.ɵccf("app-ortif-footer", i2.OrtifFooterComponent, View_OrtifFooterComponent_Host_0, {}, {}, []);
export { OrtifFooterComponentNgFactory as OrtifFooterComponentNgFactory };
