import { Component, OnInit } from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

import { ConfigStore } from '../../../store/config.store';

/**
 * Cps component
 */
@Component({
  selector: 'app-cps',
  templateUrl: './cps.component.html',
  styleUrls: ['./cps.component.scss']
})
export class CpsComponent implements OnInit {
  public CPSFullURL: string;
  public safeUrl: SafeResourceUrl;

  constructor(
    private configStore: ConfigStore,
    private sanitizer: DomSanitizer
  ) {
    this.CPSFullURL = 'about:blank';
  }

  /**
   * Init
   * @inheritDoc
   */
  ngOnInit(): void {
    setTimeout(() => {
      this.CPSFullURL =
        this.configStore.getCarteCPSAuthentUrl +
        this.configStore.getBackAuthenticator +
        '/user/login/enrs';

      this.getIframePath();
    }, 500);


  }

  /**
   * Build iframePath
   * @returns {SafeResourceUrl}
   */
  public getIframePath(): any {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.CPSFullURL);
  }
}
