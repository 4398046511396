<div>
    <div class="tab-header-wrapper">
        <a class="tab-header active">
            <span>
                Bonjour, vous avez été invité à consulter un télédossier</span>
        </a>
    </div>
    <div class="tab active">
        <div class="card offset-lg-1" style="color:white;">
            <div class="messages">  {{'AUTH.INVITATION.MESSAGES' | translate}}</div>
            <div [hidden]="loginOk || errorId || invitationExpired" style="text-align: center; height: 100%; margin-top: 10%">
                <div class="spinner">{{ 'SPINNER' | translate }}</div>
            </div>
            <div [hidden]="!loginOk" class="code">{{'AUTH.INVITATION.CODE' | translate}}</div>
            <form [hidden]="!loginOk" (ngSubmit)="login(loginForm)" #loginForm=ngForm>
                <div class="row" style="margin-bottom: 20px;">
                    <div class="col-sm-12">
                        <input #passwordInput=ngModel autocomplete="off"
                               type="text"
                               validateOnBlur
                               required class="form-control" id="code" name="code" [(ngModel)]="user.password"
                               placeholder="{{'AUTH.INVITATION.CODEAUTH' | translate}}">
                    </div>
                </div>
                <div class="row" id="tou-container">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="termsOfUse" name="termsOfUse" required #termsOfUseCheckbox="ngModel" [(ngModel)]="termsOfUse">
                    <label class="form-check-label" for="termsOfUse">
                      Je suis un professionnel de santé et j'accepte les <a href="{{ configStore.allUrlByStatic$.cguInvitation }}" target="_blank">conditions générales d'utilisation</a>
                    </label>
                  </div>
                </div>
                <div class="row" id="btn-container">
                    <div class="col-sm-12">
                        <button class="btn-form" type="submit" style="margin-left: 6px;">
                            {{'AUTH.INVITATION.BUTTON' | translate}}
                        </button>
                    </div>
                </div>
            </form>
            <div class="row" >
                <div class="col-sm-12 col-lg-9">
                    <div [hidden]="!errorId" class="form-error-message">{{'WD-INVIT-022_1' | translate}}</div>
                </div>
                <div class="col-sm-12 col-lg-9">
                    <div [hidden]="!errorId" class="form-error-message">{{'WD-INVIT-022_2' | translate}}</div>
                </div>
                <div class="col-sm-12 col-lg-9">
                    <div [hidden]="!loginError" class="form-error-message">{{'AUTH_WD_017' | translate}}</div>
                </div>
                <div class="col-sm-12 col-lg-9">
                    <div [hidden]="!codeError" class="form-error-message">{{ 'WD-INVIT-022_3' | translate }}</div>
                </div>
                <div class="col-sm-12 col-lg-9">
                    <div [hidden]="!touError" class="form-error-message">{{ 'WD-INVIT-022_4' | translate }}</div>
                </div>
            </div>
            <div class="row" >
                <div class="col-sm-12 col-lg-9">
                    <div [hidden]="!invitationExpired" class="form-error-message">{{'WD-INVIT-019' | translate}}</div>
                </div>
            </div>
            <!-- <div [hidden]="errorId || loginError || invitationExpired || !loginOk" class="mobile">{{'AUTH.INVITATION.MOBILE' | translate}}</div> -->
        </div>
    </div>
</div>
