import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppConfig } from './app.config';
/**
 * Component
 * */
import { HeaderComponent } from './pages/commons/header/header.component';
import { OrtifFooterComponent } from './pages/commons/ortif-footer/ortif-footer.component';
import { AppComponent } from './app.component';
import { AuthComponent } from './pages/auth/auth.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { RegistrationComponent } from './pages/auth/registration/registration.component';
import { ProtectedRegistrationComponent } from './pages/auth/protected-registration/protected-registration.component';
import { CpsComponent } from './pages/auth/cps/cps.component';
import { StrongLoginComponent } from './pages/auth/strong-login/strong-login.component';
import { InvitationComponent } from './pages/auth/invitation/invitation.component';
import { InvitationAcetiamComponent } from './pages/invitation-acetiam/invitation-acetiam.component';
import { ExternalLinkComponent } from './pages/external-link/external-link.component';
import { ErrorComponent } from './pages/error/error.component';
import { OrtifLayoutComponent } from './_layouts/ortif-layout/ortif-layout.component';
import { AcetiamLayoutComponent } from './_layouts/acetiam-layout/acetiam-layout.component';

/**
 * Services
 */
import { Api } from './services/api/api.service';
import { LoginService } from './services/login/login.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { ErrorService } from './services/error/error.service';
import { SpinnerService, UtilsService} from './services/utils/';
import { AppReadyEvent } from './events/';

/** MobX */
import { ConfigStore } from './store/config.store';

/**
 * import ngx-translate and the http loader
 */
export function initializeApp(appConfig: AppConfig): any {
  return (): any => appConfig.onAppInit();
}

/**
 * required for AOT compilation
 * @param http
 * @constructor
 */
export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/**
 * Module
 */
@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    LoginComponent,
    RegistrationComponent,
    ProtectedRegistrationComponent,
    CpsComponent,
    StrongLoginComponent,
    HeaderComponent,
    OrtifFooterComponent,
    InvitationComponent,
    InvitationAcetiamComponent,
    ExternalLinkComponent,
    ErrorComponent,
    OrtifLayoutComponent,
    AcetiamLayoutComponent
  ],
  imports: [
    AppRoutingModule,
    NgbModule.forRoot(),
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [],
  schemas: [NO_ERRORS_SCHEMA],

  providers: [
    Api,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig], multi: true
    },
    LoginService,
    AuthenticationService,
    ConfigStore,
    AppReadyEvent,
    ErrorService,
    SpinnerService,
    UtilsService
  ],
  bootstrap: [AppComponent]
})


export class AppModule {
}
