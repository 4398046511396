import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {User} from '../../../models/user/user.model';
import {LoginService} from '../../../services/login/login.service';
import {_} from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import {TranslateService} from '@ngx-translate/core';
import {ConfigStore} from '../../../store/config.store';

declare var $: any;

/**
 * Register component
 */
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  // User object to register
  public user: User;
  public termOfUse: boolean;
  // Boolean to know if user try to submit register form
  public accountSubmitted: boolean = false;
  // Boolean to know if account has been submit and server OK
  public accountRegister: boolean = false;
  public registerErrorMessage: string = null;
  public registerErrorKey: string = null;
  public spinn: boolean = false;
  private regExpPassword: RegExp;
  private submitOnce: boolean = false;

  // Array to map error code with message translator key
  private registerErrorMessageMap: any = {
    [LoginService.ENRS_CODE_DUPLICATE_USER_EMAIL]: _('AUTH_WD_002'),
    [LoginService.ENRS_CODE_DUPLICATE_USER_NATIONALID]: _('AUTH_WD_004'),
    [LoginService.ENRS_CODE_PASSWORD_COMPLEXITY]: _('AUTH_WD_006'),
    [LoginService.ENRS_CODE_TECHNIQUE]: _('AUTH_WD_009'),
    [LoginService.API_CODE_CAPTCHA_ERROR]: ('AUTH_WD_018')
  };

  constructor(private loginService: LoginService, private translate: TranslateService, private configStore: ConfigStore) {
    this.user = new User;
    this.regExpPassword = new RegExp(this.configStore.getRegexPassword);
  }

  /**
   * TODO To be completed
   */
  ngOnInit(): void {
    /*
      Plugin realperson has document.ready to init a salt var.
      if plugin run before the salt init, we have error to validate captcha.
      Conclusion => init plugin at document ready
    */
    $(() => {
      $('#captcha').realperson({regenerate: this.translate.instant('AUTH.REGISTER.CAPTCHA.CHANGE')});
    });
  }


  /**
   * POST register form to the server
   * @param registrationForm
   */
  registerNewAccount(registrationForm: NgForm): void {
    // Check password complexity
    if (!this.regExpPassword.test(this.user.password) && this.user.password.length !== 0) {
      this.registerErrorKey = 'AUTH_WD_006';
      this.submitOnce = true;
      return;
    }

    if (this.user.password !== this.user.passwordConfirmation) {
      this.registerErrorKey = 'AUTH_WD_012';
      this.submitOnce = true;
      return;
    }

    this.accountSubmitted = true;

    if (registrationForm.valid) {
      this.spinn = true;
      // Generate data for asked Captcha API
      this.user.captchaHash = $('#captcha').realperson('getHash');
      this.loginService.postRegister(this.user).subscribe(
        (data: any) => this.registerValidate(data),
        (error: any) => this.registerError(error)
      );
    }
  }

  /**
   * Method call after success on register POST
   * @param data
   */
  private registerValidate(data: any): void {
    this.accountRegister = true;
    this.spinn = false;
    this.registerErrorMessage = '';
  }

  /**
   * Method call if error occured after the register POST
   * @param response
   */
  private registerError(response: any): void {
    const translateKey = this.registerErrorMessageMap.hasOwnProperty(response.error.internCode) ?
      this.registerErrorMessageMap[response.error.internCode] : 'AUTH_WD_009';
    this.registerErrorKey = translateKey;
    this.registerErrorMessage = this.translate.instant(translateKey);
    this.spinn = false;
  }

  /**
   * remove msg when password change
   */
  public testPassword(): void {
    if (this.registerErrorKey === 'AUTH_WD_006' && (this.regExpPassword.test(this.user.password) || this.user.password.length === 0)) {
      this.registerErrorKey = '';
    }
    if (this.submitOnce && this.user.password.length !== 0 && !this.regExpPassword.test(this.user.password)) {
      this.registerErrorKey = 'AUTH_WD_006';
    }
  }

  /**
   * Reset error, prevent to display an error on another
   */
  resetRegisterError(): void {
    if (this.registerErrorKey === 'AUTH_WD_002') {
      this.registerErrorKey = '';
    }
  }
}
