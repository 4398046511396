import { OnInit } from '@angular/core';
import { User } from '../../../models/user/user.model';
import { LoginService } from '../../../services/login/login.service';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { ConfigStore } from '../../../store/config.store';
/**
 * Register component
 */
var RegistrationComponent = /** @class */ (function () {
    function RegistrationComponent(loginService, translate, configStore) {
        this.loginService = loginService;
        this.translate = translate;
        this.configStore = configStore;
        // Boolean to know if user try to submit register form
        this.accountSubmitted = false;
        // Boolean to know if account has been submit and server OK
        this.accountRegister = false;
        this.registerErrorMessage = null;
        this.registerErrorKey = null;
        this.spinn = false;
        this.submitOnce = false;
        // Array to map error code with message translator key
        this.registerErrorMessageMap = (_a = {},
            _a[LoginService.ENRS_CODE_DUPLICATE_USER_EMAIL] = _('AUTH_WD_002'),
            _a[LoginService.ENRS_CODE_DUPLICATE_USER_NATIONALID] = _('AUTH_WD_004'),
            _a[LoginService.ENRS_CODE_PASSWORD_COMPLEXITY] = _('AUTH_WD_006'),
            _a[LoginService.ENRS_CODE_TECHNIQUE] = _('AUTH_WD_009'),
            _a[LoginService.API_CODE_CAPTCHA_ERROR] = ('AUTH_WD_018'),
            _a);
        this.user = new User;
        this.regExpPassword = new RegExp(this.configStore.getRegexPassword);
        var _a;
    }
    /**
     * TODO To be completed
     */
    RegistrationComponent.prototype.ngOnInit = function () {
        var _this = this;
        /*
          Plugin realperson has document.ready to init a salt var.
          if plugin run before the salt init, we have error to validate captcha.
          Conclusion => init plugin at document ready
        */
        $(function () {
            $('#captcha').realperson({ regenerate: _this.translate.instant('AUTH.REGISTER.CAPTCHA.CHANGE') });
        });
    };
    /**
     * POST register form to the server
     * @param registrationForm
     */
    RegistrationComponent.prototype.registerNewAccount = function (registrationForm) {
        var _this = this;
        // Check password complexity
        if (!this.regExpPassword.test(this.user.password) && this.user.password.length !== 0) {
            this.registerErrorKey = 'AUTH_WD_006';
            this.submitOnce = true;
            return;
        }
        if (this.user.password !== this.user.passwordConfirmation) {
            this.registerErrorKey = 'AUTH_WD_012';
            this.submitOnce = true;
            return;
        }
        this.accountSubmitted = true;
        if (registrationForm.valid) {
            this.spinn = true;
            // Generate data for asked Captcha API
            this.user.captchaHash = $('#captcha').realperson('getHash');
            this.loginService.postRegister(this.user).subscribe(function (data) { return _this.registerValidate(data); }, function (error) { return _this.registerError(error); });
        }
    };
    /**
     * Method call after success on register POST
     * @param data
     */
    RegistrationComponent.prototype.registerValidate = function (data) {
        this.accountRegister = true;
        this.spinn = false;
        this.registerErrorMessage = '';
    };
    /**
     * Method call if error occured after the register POST
     * @param response
     */
    RegistrationComponent.prototype.registerError = function (response) {
        var translateKey = this.registerErrorMessageMap.hasOwnProperty(response.error.internCode) ?
            this.registerErrorMessageMap[response.error.internCode] : 'AUTH_WD_009';
        this.registerErrorKey = translateKey;
        this.registerErrorMessage = this.translate.instant(translateKey);
        this.spinn = false;
    };
    /**
     * remove msg when password change
     */
    RegistrationComponent.prototype.testPassword = function () {
        if (this.registerErrorKey === 'AUTH_WD_006' && (this.regExpPassword.test(this.user.password) || this.user.password.length === 0)) {
            this.registerErrorKey = '';
        }
        if (this.submitOnce && this.user.password.length !== 0 && !this.regExpPassword.test(this.user.password)) {
            this.registerErrorKey = 'AUTH_WD_006';
        }
    };
    /**
     * Reset error, prevent to display an error on another
     */
    RegistrationComponent.prototype.resetRegisterError = function () {
        if (this.registerErrorKey === 'AUTH_WD_002') {
            this.registerErrorKey = '';
        }
    };
    return RegistrationComponent;
}());
export { RegistrationComponent };
