import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import {HttpClient} from '@angular/common/http';
import {ConfigStore} from '../../store/config.store';

/**
 * Manage authentication (dashboard and establishment)
 */
@Injectable()
export class AuthenticationService {
  constructor(private configStore: ConfigStore, private httpClient: HttpClient) {
  }

  /**
   * TODO To be completed
   */
  getHeaderForm(): any {
    return {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    };
  }

  /**
   * GetContext
   * Get the context to send an otp
   * @param {string} typeOtp
   * @returns {Observable<any>}
   */
  getContext(typeOtp: string): any {

    return this.httpClient
      .post(this.configStore.getSng + '/doAuth.json?SCHEMANAME=' + typeOtp, null, this.getHeaderForm())
      .map((response: any) => response);
  }

  /**
   * SendOtp
   * Send an otp for a login
   * @param {string} contextId
   * @param {string} login
   * @returns {Observable<any>}
   */
  sendOTP(contextId: string, login: string): any {
    // context.response.param[0].value
    return this.httpClient
      .post(this.configStore.getSng + '/doAuth.json?AUTHENTICATION' +
        '.LOGIN='
        + login + '&CONTEXTID=' + contextId, null, this.getHeaderForm())
      .map((response: any) => response);
  }

  /**
   *  ValidOTP
   *  Valid the otp sent for a user and a context
   * @param {string} contextId
   * @param {string} password
   * @returns {Observable<any>}
   */
  validOTP(contextId: string, password: string): Observable<any> {
    return this.httpClient
      .post(this.configStore.getSng + '/doAuth.json?AUTHENTICATION.PASSWORD='
        + password + '&CONTEXTID=' + contextId, null, this.getHeaderForm())
      .map((response: any) => response);
  }
}

