import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

/**
 * Header Componenet
 */
@Component({
  selector: 'app-ortif-footer',
  templateUrl: './ortif-footer.component.html',
  styleUrls: ['./ortif-footer.component.scss']
})
export class OrtifFooterComponent implements OnInit {

  public version: string;

  public copyright: number;

  /**
   * Init
   */
  ngOnInit(): void {
    this.version = environment.PORTAIL_VERSION_GIT_TAG;
    this.copyright = (new Date()).getFullYear();
  }

}
