/**
 * Error component - displayed if static config is unreachable
 */
var ErrorService = /** @class */ (function () {
    function ErrorService() {
    }
    Object.defineProperty(ErrorService.prototype, "error", {
        get: function () {
            return this._error;
        },
        set: function (error) {
            this._error = error;
        },
        enumerable: true,
        configurable: true
    });
    return ErrorService;
}());
export { ErrorService };
