<div>
  <h2>{{ 'AUTH.INVITATION.MESSAGES' | translate }}</h2>
  <div [hidden]="loginOk || errorId || invitationExpired" style="text-align: center; height: 100%; margin-top: 10%">
    <div class="spinner">{{ 'SPINNER' | translate }}</div>
  </div>
  <div class="formContainer">
    <p [hidden]="!loginOk">{{ 'AUTH.INVITATION.CODE' | translate }}</p>
    <form [hidden]="!loginOk">
      <div class="form-group">
        <input #passwordInput=ngModel autocomplete="off" validateOnBlur type="text" email required
               placeholder="{{ 'AUTH.INVITATION.CODEAUTH' | translate }}"
               class="form-control"
               id="code" name="code" [(ngModel)]="user.password">
      </div>
      <div class="form-group text-center">
        <button [disabled]="spinn" class="btn btn-primary" type="submit" (click)="login()">
          <span [style.visibility]="spinn ? 'visible' : 'hidden'" class="spinner spinner-inline"></span>
          <span>{{ 'AUTH.INVITATION.BUTTON' | translate }}</span>
        </button>
      </div>
    </form>
    <div class="row">
      <div class="col-sm-12 col-lg-9">
        <div [hidden]="!errorId" class="form-error-message">{{ 'WD-INVIT-022_1' | translate }}</div>
      </div>
      <div class="col-sm-12 col-lg-9">
        <div [hidden]="!errorId" class="form-error-message">{{ 'WD-INVIT-022_2' | translate }}</div>
      </div>

      <div class="col-sm-12 col-lg-9">
        <div [hidden]="!loginError" class="form-error-message">{{ 'AUTH_WD_017' | translate }}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-9">
        <div [hidden]="!invitationExpired" class="form-error-message">{{ 'WD-INVIT-019' | translate }}</div>
      </div>
    </div>
    <div [hidden]="errorId || loginError || invitationExpired || !loginOk"
         class="mobile small">{{ 'AUTH.INVITATION.MOBILE' | translate }}
    </div>
  </div>
</div>
