<div class="wrapper">
    <div class="blue-square"></div>
    <div class="container">
        <div class="row main-row">
            <div class="col-12 col-lg-6 main-col">
                <div class="content-wrapper">
                    <div class="logo-wrapper">
                        <img src="./assets/images/nexus-platform.svg" width="225" height="56">
                    </div>
                    <app-invitation-acetiam></app-invitation-acetiam>
                    <div class="spacer"></div>
                </div>
                <div class="footer">
                    <div class="footer-border"></div>
                    <div class="footer-brands">
                        <img class="mb-1" src="./assets/images/LOGOTYPE_NEHS_DIGITAL.svg" alt="" width="97" height="27">
                        <p>
                            © Copyright NEHS DIGITAL
                          <!--
                            - <a href="https://www.acetiam.eu/fr/mentions-legales">Mentions légales</a>
                            - <a href="https://www.acetiam.eu/fr/notice-traitement-donnes-personnelles/">Notice traitement des données personnelles</a>
                            - <a href="https://www.acetiam.eu/fr/cookies/">Gestion des cookies</a>
                          -->
                        </p>
                    </div>
                    <div class="footer-border"></div>
                </div>
            </div>
        </div>
    </div>
</div>
