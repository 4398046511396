import { Injectable } from '@angular/core';
import { SpinnerService } from './spinner.service';

/**
 * TODO To be completed
 */
@Injectable()
export class UtilsService {

  constructor(
    private spinnerService: SpinnerService
  ) { }

  /**
   * assign external url
   * @param {string} url
   */
  public assignUrl(url: string): void {
    this.spinnerService.show();
    window.location.assign(url);
  }
}
