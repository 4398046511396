import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
/* tslint:disable*/
import {Config} from 'npm-module-configuration/index.umd';
/* tslint:disable*/
import {LOGGER} from 'npm-module-logger/index.umd';
import {ErrorService} from './services/error/error.service';
import {Router} from '@angular/router';

LOGGER.outFile = false;
LOGGER.outSyslog = false;
LOGGER.outConsole = true;

/**
 * Component
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  title: string = 'app';

  constructor(
    private translate: TranslateService,
    private errorService: ErrorService,
    private router: Router
  ) {}

  /**
   * Init
   */
  ngOnInit(): void {
    if (this.errorService.error) {
      this.router.navigate(['fr/error']);
    }
    this.translate.setDefaultLang('fr');
  }
}
