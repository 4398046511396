import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'npm-module-configuration/index.umd';

import { IAppConfig } from '../assets/conf/app-config.model';
import { environment } from '../environments/environment';
import { AppReadyEvent } from './events';
import { ErrorService } from './services/error/error.service';
import { ConfigStore } from './store/config.store';

const LIMIT = 50;
const TIMEOUT_DURATION = 100;

/**
 * TODO To be completed
 */
@Injectable()
export class AppConfig {

  static settings: IAppConfig;
  private count: number = 0;

  constructor(
    private http: HttpClient,
    private configStore: ConfigStore,
    private errorService: ErrorService,
    private appReadyEvent: AppReadyEvent
  ) {
  }

  /**
   * Checks whether if config is ready & checks it LIMIT times
   * @param config
   * @returns {Promise<boolean>}
   */
  private isConfigReady(config: any): Promise<boolean> {
    return new Promise((resolve: any, reject: any): void => {
      if (config.ready === false && this.count < LIMIT) {
        this.count++;
        reject();
      } else {
        if (config.ready) {
          config.getKey('environment').then((data: any) => {
            AppConfig.settings = data;
            this.configStore.setAllUrlByStatic(data);

            // set configuration for all applications
            if (localStorage) {
              localStorage.setItem('app-configuration', JSON.stringify(data));
            }

            resolve(true);
          }).catch((e: any) => {
            console.log('error, "environment" key was not found', e);
            resolve(false);
          });
          config.getKey('regex').then((data: any) => {
            this.configStore.setRegexPassword(data.password);
            resolve(true);
          }).catch((e: any) => {
            console.log('error, "regex" key was not found', e);
            resolve(false);
          });
        } else {
          reject(true);
        }
      }
    });
  }

  /**
   * Init
   */
  onAppInit(): Promise<any> {
    const jsonFile = './assets/conf/config.' + environment.name + '.json';
    return new Promise(
      (resolve: any, reject: any): any => {


        // clear configuration in local storage
        if (localStorage) {
          localStorage.removeItem('app-configuration');
        }

        try {
          this.http.get(jsonFile)
            .subscribe((res: IAppConfig) => {
              const c = new Config(res.CONFIG_STATIC.url);

              this.isConfigReady(c).then(() => {
                resolve(true);
              }, this.retryIsConfigReady(c, resolve, reject));
            },
              (error: any) => (console.log('error', error)));
        } catch (e) {
          console.log(e);
          reject();
        }
      });
  }

  /**
   * Sets an error for application init failed
   */
  private setAppInitError(): void {
    // Cannot use translateservice because it is too early in app init
    const adaptedError = {
      code: null,
      content: 'Une erreur est survenue. Merci de réitérer ultérieurement'
    };
    this.errorService.error = adaptedError;
    const testData = {
      backAuthenticator: ''
    };

    this.appReadyEvent.trigger();

    // set error & resolve & then in app component check error presence
    // cannot be done unless we set AllUrlByStatic to default values
    this.configStore.setAllUrlByStatic(testData);
  }

  /**
   * Tries to check if config is ready untill it is or untill LIMIT is reached
   * @param {} c
   * @param {Function} resolve
   * @param {Function} reject
   * @returns {any}
   */
  private retryIsConfigReady(c: Config, resolve: Function, reject: Function): any {
    return (): void => {
      // check every TIMEOUT_DURATION
      setTimeout(() => {
        this.isConfigReady(c).then(() => {
          resolve(true);
        }, (error: any) => {
          if (error) {
            this.setAppInitError();
            resolve();
          } else {
            this.retryIsConfigReady(c, resolve, reject)();
          }
        });
      }, TIMEOUT_DURATION);
    };
  }
}
