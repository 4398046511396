import { Injectable } from '@angular/core';
import { SingleLoader } from 'npm-module-loader/dist/index.umd';

/**
 * TODO To be completed
 */
@Injectable()
export class SpinnerService {

  /**
   * show external spinner
   */
  public show(): void {
    SingleLoader.loader.showSpinner();
  }
  /**
  * hide external spinner
  */
  public hide(): void {
    SingleLoader.loader.hideSpinner();
  }
}
