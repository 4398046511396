<div id="logoContainer">
  <h1>
    <a [routerLink]="['/']"><img src="./assets/images/logo_ORTIF.png" width="254" alt="ORTIF"></a>
  </h1>
  <a id="tooltipContainer">
    <img src="./assets/images/pictos/aide.png" width="51" height="51" alt="Aide">
    <div id="tooltip">
      <div class="first"><a href="https://formation.sesan.fr/ortif/" target="_blank">Découvrir ORTIF</a></div>
      <div><a href="{{ helpConnect || '' }}" target="_blank">Aide à la connexion</a></div>
    </div>
  </a>
</div>
