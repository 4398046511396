/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./auth.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./login/login.component.ngfactory";
import * as i3 from "./login/login.component";
import * as i4 from "../../services/login/login.service";
import * as i5 from "../../store/config.store";
import * as i6 from "@angular/forms";
import * as i7 from "../../services/utils/utils.service";
import * as i8 from "./registration/registration.component.ngfactory";
import * as i9 from "./registration/registration.component";
import * as i10 from "@ngx-translate/core";
import * as i11 from "../external-link/external-link.component.ngfactory";
import * as i12 from "../external-link/external-link.component";
import * as i13 from "./invitation/invitation.component.ngfactory";
import * as i14 from "./invitation/invitation.component";
import * as i15 from "../../services/api/api.service";
import * as i16 from "../commons/ortif-footer/ortif-footer.component.ngfactory";
import * as i17 from "../commons/ortif-footer/ortif-footer.component";
import * as i18 from "./auth.component";
import * as i19 from "@angular/router";
import * as i20 from "../../events/app-ready.events";
var styles_AuthComponent = [i0.styles];
var RenderType_AuthComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthComponent, data: {} });
export { RenderType_AuthComponent as RenderType_AuthComponent };
export function View_AuthComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["id", "accordion"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-login", [], [[8, "hidden", 0]], null, null, i2.View_LoginComponent_0, i2.RenderType_LoginComponent)), i1.ɵdid(2, 114688, null, 0, i3.LoginComponent, [i4.LoginService, i5.ConfigStore, i6.FormBuilder, i7.UtilsService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-registration", [], [[8, "hidden", 0]], [[null, "register"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("register" === en)) {
        var pd_0 = (_co.onRegister($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_RegistrationComponent_0, i8.RenderType_RegistrationComponent)), i1.ɵdid(4, 114688, null, 0, i9.RegistrationComponent, [i4.LoginService, i10.TranslateService, i5.ConfigStore], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-external-link", [], [[8, "hidden", 0]], null, null, i11.View_ExternalLinkComponent_0, i11.RenderType_ExternalLinkComponent)), i1.ɵdid(6, 114688, null, 0, i12.ExternalLinkComponent, [i5.ConfigStore], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-invitation", [], [[8, "hidden", 0]], null, null, i13.View_InvitationComponent_0, i13.RenderType_InvitationComponent)), i1.ɵdid(8, 114688, null, 0, i14.InvitationComponent, [i4.LoginService, i15.Api, i5.ConfigStore, i7.UtilsService], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-ortif-footer", [], null, null, null, i16.View_OrtifFooterComponent_0, i16.RenderType_OrtifFooterComponent)), i1.ɵdid(10, 114688, null, 0, i17.OrtifFooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); _ck(_v, 6, 0); _ck(_v, 8, 0); _ck(_v, 10, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.invitationId !== 0); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.invitationId !== 0); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.invitationId !== 0); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.invitationId === 0); _ck(_v, 7, 0, currVal_3); }); }
export function View_AuthComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-auth", [], null, null, null, View_AuthComponent_0, RenderType_AuthComponent)), i1.ɵdid(1, 114688, null, 0, i18.AuthComponent, [i19.Router, i19.ActivatedRoute, i5.ConfigStore, i20.AppReadyEvent], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthComponentNgFactory = i1.ɵccf("app-auth", i18.AuthComponent, View_AuthComponent_Host_0, {}, {}, []);
export { AuthComponentNgFactory as AuthComponentNgFactory };
