import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {User} from '../../../models/user/user.model';
import {LoginService} from '../../../services/login/login.service';
import {_} from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import {TranslateService} from '@ngx-translate/core';
import {ConfigStore} from '../../../store/config.store';
import { REGISTRATION_REDIRECT_TIMEOUT } from '../../commons/constants';
import { Router } from '@angular/router';

declare var $: any;

/**
 * Register component
 */
@Component({
  selector: 'app-protected-registration',
  templateUrl: './protected-registration.component.html',
  styleUrls: ['./protected-registration.component.scss']
})
export class ProtectedRegistrationComponent implements OnInit {

  // User object to register
  public user: User;
  public isHpEmail: boolean;
  public termOfUse: boolean;
  // Boolean to know if user try to submit register form
  public accountSubmitted: boolean = false;
  // Boolean to know if account has been submit and server OK
  public accountRegister: boolean = false;
  public registerErrorMessage: string = null;
  public registerErrorKey: string = null;
  public spinn: boolean = false;
  private regExpPassword: RegExp;
  private submitOnce: boolean = false;
  public locked: boolean = true;
  @ViewChild('pinCodeModal')
  private pinCodeModal: ElementRef;
  public pinCodeError: string = '';
  public pinCodeSpinner: boolean = false;

  // Array to map error code with message translator key
  private registerErrorMessageMap: any = {
    [LoginService.ENRS_CODE_DUPLICATE_USER_EMAIL]: _('AUTH_WD_002'),
    [LoginService.ENRS_CODE_DUPLICATE_USER_NATIONALID]: _('AUTH_WD_004'),
    [LoginService.ENRS_CODE_PASSWORD_COMPLEXITY]: _('AUTH_WD_006'),
    [LoginService.ENRS_CODE_TECHNIQUE]: _('AUTH_WD_009'),
    [LoginService.API_CODE_CAPTCHA_ERROR]: ('AUTH_WD_018')
  };

  constructor(private loginService: LoginService,
              private translate: TranslateService,
              private configStore: ConfigStore,
              private router: Router) {
    this.user = new User;
    this.regExpPassword = new RegExp(this.configStore.getRegexPassword);
  }

  /**
   * TODO To be completed
   */
  ngOnInit(): void {
    if (this.locked) {
      this.initModal();
    }
    /*
      Plugin realperson has document.ready to init a salt var.
      if plugin run before the salt init, we have error to validate captcha.
      Conclusion => init plugin at document ready
    */
    $($('#captcha').realperson({regenerate: this.translate.instant('AUTH.REGISTER.CAPTCHA.CHANGE')}));
  }

  /**
   * Init modal
   * @private
   */
  private initModal(): void {
    const inputs = this.pinCodeModal.nativeElement.querySelectorAll('input[type=password]');
    inputs.forEach((input: any, index: any) => {
      const next = index + 1;
      input.addEventListener('keyup', (evt: any) => {
        if (evt.key.length !== 1) {
          return;
        }
        if (next >= inputs.length) {
          this.validatePinCode();
          return;
        }
        inputs[next].focus();
      });
    });
    inputs[0].focus();
  }

  /**
   * Ask authenticator for pin code validation
   * @private
   */
  validatePinCode(): void {
    this.pinCodeError = '';
    const pinCodeFields = this.pinCodeModal.nativeElement.querySelectorAll('input[type=password]');
    const pinCodeNumbers = [];
    Array.from(pinCodeFields)
      .forEach((field: any) => {
        pinCodeNumbers.push(field.value);
        field.value = '';
      });
    pinCodeFields[0].focus();
    const pinCode = pinCodeNumbers.join('');
    if (pinCode.length !== 6) {
      this.pinCodeError = this.translate.instant('AUTH_WD_030');
      return;
    }
    this.pinCodeSpinner = true;
    this.loginService.postValidateRegistrationPinCode({pinCode}).subscribe((result: any) => {
      this.pinCodeSpinner = false;
      this.locked = false;
    }, (result: any) => {
      this.pinCodeSpinner = false;
      const errorMsg = result.status === 403 ? 'AUTH_WD_031' : 'WD-ERR-001';
      this.pinCodeError = this.translate.instant(errorMsg);
    });
  }

  /**
   * POST register form to the server
   * @param registrationForm
   */
  registerNewAccount(registrationForm: NgForm): void {
    if (this.locked) {
      return;
    }
    // Check password complexity
    if (!this.regExpPassword.test(this.user.password) && this.user.password.length !== 0) {
      this.registerErrorKey = 'AUTH_WD_006';
      this.submitOnce = true;
      return;
    }

    if (this.user.password !== this.user.passwordConfirmation) {
      this.registerErrorKey = 'AUTH_WD_012';
      this.submitOnce = true;
      return;
    }

    this.accountSubmitted = true;

    if (registrationForm.valid) {
      this.spinn = true;
      // Generate data for asked Captcha API
      this.user.captchaHash = $('#captcha').realperson('getHash');
      this.loginService.postRegister(this.user).subscribe(
        (data: any) => this.registerValidate(data),
        (error: any) => this.registerError(error)
      );
    }
  }

  /**
   * Method call after success on register POST
   * @param data
   */
  private registerValidate(data: any): void {
    this.accountRegister = true;
    this.spinn = false;
    this.registerErrorMessage = '';
    setTimeout(() => {
      this.router.navigate(['/index']);
    }, 1000 * REGISTRATION_REDIRECT_TIMEOUT);
  }

  /**
   * Method call if error occured after the register POST
   * @param response
   */
  private registerError(response: any): void {
    const translateKey = this.registerErrorMessageMap.hasOwnProperty(response.error.internCode) ?
      this.registerErrorMessageMap[response.error.internCode] : 'AUTH_WD_009';
    this.registerErrorKey = translateKey;
    this.registerErrorMessage = this.translate.instant(translateKey);
    this.spinn = false;
  }

  /**
   * remove msg when password change
   */
  public testPassword(): void {
    if (this.registerErrorKey === 'AUTH_WD_006' && (this.regExpPassword.test(this.user.password) || this.user.password.length === 0)) {
      this.registerErrorKey = '';
    }
    if (this.submitOnce && this.user.password.length !== 0 && !this.regExpPassword.test(this.user.password)) {
      this.registerErrorKey = 'AUTH_WD_006';
    }
  }

  /**
   * Reset error, prevent to display an error on another
   */
  resetRegisterError(): void {
    if (this.registerErrorKey === 'AUTH_WD_002') {
      this.registerErrorKey = '';
    }
  }
}
