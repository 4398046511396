import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {ConfigStore} from '../../store/config.store';
import * as Constants from '../../pages/commons/constants';
/**
 * Api Service
 */
@Injectable()
export class Api {

  token: String;
  // Connector to API ENRS
  API_URL: String;
  // Connector to API IDSphere
  API_DIRECTORY: String;

  constructor(
    private http: HttpClient,
    private configStore: ConfigStore
  ) {
    this.token = null;
    this.API_URL = this.configStore.getAllUrlByStatic.backAuthenticator;
    this.API_DIRECTORY = this.configStore.getAllUrlByStatic.backDirectory;
  }

  /**
   * Http GET
   * @param route
   */
  get(route: string): Observable<any> {
    return this.http.get(`${this.API_URL}${route}`, this.getHeader());
  }

  /**
   * Http POST
   * @param route
   * @param body
   */
  post(route: string, body: any): Observable<any> {
    return this.http.post(`${this.API_URL}${route}`, body, this.getHeader());
  }

  /**
   * TODO To be completed
   * @param route
   * @param body
   */
  postFullResponse(route: string, body: any): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.API_URL}${route}`, body, {observe: 'response'});
  }

  /**
   * Request Get to back-directory
   * @param route
   * @param jwt
   */
  getDirectory(route: string, jwt?: string): Observable<any> {
    return this.http.get(`${this.API_DIRECTORY}${route}`, this.getHeaderDirectory(jwt));
  }

  /**
   * Create header for directory calls
   * @param {string} jwt
   * @returns {any}
   */
  private getHeaderDirectory(jwt?: string): any {
    return {
      observe: 'response',
      responseType: 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + (jwt ? jwt : localStorage.getItem(Constants.SESSION_TOKEN))
      })
    };

  }

  /**
   * Create header
   */
  private getHeader(): any {
    return {
      observe: 'response',
      responseType: 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': (localStorage.getItem(Constants.SESSION_TOKEN) != null) ? localStorage.getItem(Constants.SESSION_TOKEN) : ''
      })
    };

  }

  /**
   * Http POST
   * @param route
   * @param body
   */
  searchUserFromIds(route: string, body: any, authorization: string): Observable<any> {
    return this.http.post(`${this.API_DIRECTORY}${route}`, body,
      {
        observe: 'response',
        responseType: 'json',
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': authorization ? authorization : ''
        })
      }
      );
  }


}
