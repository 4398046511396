/**
 * User model
 */
export class User {
  civility: string;
  firstName: string;
  lastName: string;
  email: string;
  userName: string;
  type: string;
  password: string;
  passwordConfirmation: string;
  captcha: string;
  captchaHash: string;
  hasHpEmail: boolean;

  jwt: string;
  tokenSignAndGo: string;
  level: 0;

  constructor() {
    this.civility = '';
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.userName = '';
    this.type = 'user';
    this.password = '';
    this.passwordConfirmation = '';
    this.captcha = '';
    this.jwt = '';
    this.tokenSignAndGo = '';
    this.level = 0;
    this.hasHpEmail = false;
  }
}
