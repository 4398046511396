/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./external-link.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./external-link.component";
import * as i3 from "../../store/config.store";
var styles_ExternalLinkComponent = [i0.styles];
var RenderType_ExternalLinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExternalLinkComponent, data: {} });
export { RenderType_ExternalLinkComponent as RenderType_ExternalLinkComponent };
export function View_ExternalLinkComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Professionnel de ville ? "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "blue external-link"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Acc\u00E9der au Portail Ville - H\u00F4pital"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", (_co.tvhLink || ""), ""); _ck(_v, 2, 0, currVal_0); }); }
export function View_ExternalLinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-external-link", [], null, null, null, View_ExternalLinkComponent_0, RenderType_ExternalLinkComponent)), i1.ɵdid(1, 114688, null, 0, i2.ExternalLinkComponent, [i3.ConfigStore], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExternalLinkComponentNgFactory = i1.ɵccf("app-external-link", i2.ExternalLinkComponent, View_ExternalLinkComponent_Host_0, {}, {}, []);
export { ExternalLinkComponentNgFactory as ExternalLinkComponentNgFactory };
