import { ElementRef, OnInit } from '@angular/core';
import { User } from '../../../models/user/user.model';
import { LoginService } from '../../../services/login/login.service';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { ConfigStore } from '../../../store/config.store';
import { REGISTRATION_REDIRECT_TIMEOUT } from '../../commons/constants';
import { Router } from '@angular/router';
/**
 * Register component
 */
var ProtectedRegistrationComponent = /** @class */ (function () {
    function ProtectedRegistrationComponent(loginService, translate, configStore, router) {
        this.loginService = loginService;
        this.translate = translate;
        this.configStore = configStore;
        this.router = router;
        // Boolean to know if user try to submit register form
        this.accountSubmitted = false;
        // Boolean to know if account has been submit and server OK
        this.accountRegister = false;
        this.registerErrorMessage = null;
        this.registerErrorKey = null;
        this.spinn = false;
        this.submitOnce = false;
        this.locked = true;
        this.pinCodeError = '';
        this.pinCodeSpinner = false;
        // Array to map error code with message translator key
        this.registerErrorMessageMap = (_a = {},
            _a[LoginService.ENRS_CODE_DUPLICATE_USER_EMAIL] = _('AUTH_WD_002'),
            _a[LoginService.ENRS_CODE_DUPLICATE_USER_NATIONALID] = _('AUTH_WD_004'),
            _a[LoginService.ENRS_CODE_PASSWORD_COMPLEXITY] = _('AUTH_WD_006'),
            _a[LoginService.ENRS_CODE_TECHNIQUE] = _('AUTH_WD_009'),
            _a[LoginService.API_CODE_CAPTCHA_ERROR] = ('AUTH_WD_018'),
            _a);
        this.user = new User;
        this.regExpPassword = new RegExp(this.configStore.getRegexPassword);
        var _a;
    }
    /**
     * TODO To be completed
     */
    ProtectedRegistrationComponent.prototype.ngOnInit = function () {
        if (this.locked) {
            this.initModal();
        }
        /*
          Plugin realperson has document.ready to init a salt var.
          if plugin run before the salt init, we have error to validate captcha.
          Conclusion => init plugin at document ready
        */
        $($('#captcha').realperson({ regenerate: this.translate.instant('AUTH.REGISTER.CAPTCHA.CHANGE') }));
    };
    /**
     * Init modal
     * @private
     */
    ProtectedRegistrationComponent.prototype.initModal = function () {
        var _this = this;
        var inputs = this.pinCodeModal.nativeElement.querySelectorAll('input[type=password]');
        inputs.forEach(function (input, index) {
            var next = index + 1;
            input.addEventListener('keyup', function (evt) {
                if (evt.key.length !== 1) {
                    return;
                }
                if (next >= inputs.length) {
                    _this.validatePinCode();
                    return;
                }
                inputs[next].focus();
            });
        });
        inputs[0].focus();
    };
    /**
     * Ask authenticator for pin code validation
     * @private
     */
    ProtectedRegistrationComponent.prototype.validatePinCode = function () {
        var _this = this;
        this.pinCodeError = '';
        var pinCodeFields = this.pinCodeModal.nativeElement.querySelectorAll('input[type=password]');
        var pinCodeNumbers = [];
        Array.from(pinCodeFields)
            .forEach(function (field) {
            pinCodeNumbers.push(field.value);
            field.value = '';
        });
        pinCodeFields[0].focus();
        var pinCode = pinCodeNumbers.join('');
        if (pinCode.length !== 6) {
            this.pinCodeError = this.translate.instant('AUTH_WD_030');
            return;
        }
        this.pinCodeSpinner = true;
        this.loginService.postValidateRegistrationPinCode({ pinCode: pinCode }).subscribe(function (result) {
            _this.pinCodeSpinner = false;
            _this.locked = false;
        }, function (result) {
            _this.pinCodeSpinner = false;
            var errorMsg = result.status === 403 ? 'AUTH_WD_031' : 'WD-ERR-001';
            _this.pinCodeError = _this.translate.instant(errorMsg);
        });
    };
    /**
     * POST register form to the server
     * @param registrationForm
     */
    ProtectedRegistrationComponent.prototype.registerNewAccount = function (registrationForm) {
        var _this = this;
        if (this.locked) {
            return;
        }
        // Check password complexity
        if (!this.regExpPassword.test(this.user.password) && this.user.password.length !== 0) {
            this.registerErrorKey = 'AUTH_WD_006';
            this.submitOnce = true;
            return;
        }
        if (this.user.password !== this.user.passwordConfirmation) {
            this.registerErrorKey = 'AUTH_WD_012';
            this.submitOnce = true;
            return;
        }
        this.accountSubmitted = true;
        if (registrationForm.valid) {
            this.spinn = true;
            // Generate data for asked Captcha API
            this.user.captchaHash = $('#captcha').realperson('getHash');
            this.loginService.postRegister(this.user).subscribe(function (data) { return _this.registerValidate(data); }, function (error) { return _this.registerError(error); });
        }
    };
    /**
     * Method call after success on register POST
     * @param data
     */
    ProtectedRegistrationComponent.prototype.registerValidate = function (data) {
        var _this = this;
        this.accountRegister = true;
        this.spinn = false;
        this.registerErrorMessage = '';
        setTimeout(function () {
            _this.router.navigate(['/index']);
        }, 1000 * REGISTRATION_REDIRECT_TIMEOUT);
    };
    /**
     * Method call if error occured after the register POST
     * @param response
     */
    ProtectedRegistrationComponent.prototype.registerError = function (response) {
        var translateKey = this.registerErrorMessageMap.hasOwnProperty(response.error.internCode) ?
            this.registerErrorMessageMap[response.error.internCode] : 'AUTH_WD_009';
        this.registerErrorKey = translateKey;
        this.registerErrorMessage = this.translate.instant(translateKey);
        this.spinn = false;
    };
    /**
     * remove msg when password change
     */
    ProtectedRegistrationComponent.prototype.testPassword = function () {
        if (this.registerErrorKey === 'AUTH_WD_006' && (this.regExpPassword.test(this.user.password) || this.user.password.length === 0)) {
            this.registerErrorKey = '';
        }
        if (this.submitOnce && this.user.password.length !== 0 && !this.regExpPassword.test(this.user.password)) {
            this.registerErrorKey = 'AUTH_WD_006';
        }
    };
    /**
     * Reset error, prevent to display an error on another
     */
    ProtectedRegistrationComponent.prototype.resetRegisterError = function () {
        if (this.registerErrorKey === 'AUTH_WD_002') {
            this.registerErrorKey = '';
        }
    };
    return ProtectedRegistrationComponent;
}());
export { ProtectedRegistrationComponent };
