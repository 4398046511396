<div>
  <h2 id="headingLogin">Déjà un compte ? <span class="expand blue" data-toggle="collapse" data-target="#collapseLogin"
                                               aria-expanded="true"
                                               aria-controls="collapseLogin">Je me connecte</span>
  </h2>
  <div id="collapseLogin" class="formContainer collapse show" aria-labelledby="headingLogin" data-parent="#accordion">
    <div [hidden]="forgottenPassword || noRights">
      <form (ngSubmit)="login(identInput, passwordInput)">
        <div class="form-group">
          <input #identInput=ngModel id="authAutoconnect" validateOnBlur required type="text"
                 class="form-control" placeholder="{{'COMMON.IDENTIFIER' | translate}}" name="userName"
                 [(ngModel)]="user.userName" autocomplete="name">
          <div class="error">
            <div
              [hidden]="(!formSubmitted && (identInput.valid || identInput.pristine ||identInput.untouched)) || (formSubmitted && identInput.valid)"
              class="form-error-message">{{ 'AUTH_WD_008' | translate }}
            </div>
          </div>
        </div>
        <div class="form-group">
          <input #passwordInput="ngModel" id="passwordAutoconnect" validateOnBlur required type="password"
                 class="form-control" placeholder="{{'COMMON.PASSWORD' | translate}}" name="password"
                 [(ngModel)]="user.password" autocomplete="current-password">
          <div class="error">
            <div
              [hidden]="(!formSubmitted && (passwordInput.valid || passwordInput.pristine ||passwordInput.untouched)) || (formSubmitted && passwordInput.valid)"
              class="form-error-message">{{ 'AUTH_WD_008' | translate }}
            </div>
          </div>
        </div>
        <div class="form-group text-center">
          <button [disabled]="spinn" type="submit" class="btn btn-primary">
            <span [style.visibility]="spinn ? 'visible' : 'hidden'" class="spinner spinner-inline"></span>
            <span>Se connecter</span>
          </button>
        </div>
        <div class="form-group text-center"><a
          (click)="loadForgotPassword()">{{ 'COMMON.FORGOTTENPASSWORD' | translate }}</a></div>
        <div class="error">
          <div [hidden]="!formSubmitted || !loginError" class="form-error-message">{{ 'AUTH_WD_001' | translate }}
          </div>
        </div>
        <div class="error">
          <div [hidden]="!idsKo" class="form-error-message">{{ 'AUTH_WD_026' | translate }}</div>
        </div>
      </form>
      <h3 class="text-center">Connexion avec ma carte CPS ou e-CPS</h3>
      <app-cps></app-cps>
    </div>
    <div [hidden]="!forgottenPassword">
      <form [formGroup]="forgottenPasswordForm" (ngSubmit)="onForgotPasswordSubmit()">
        <div class="form-group">
          <input validateOnBlur type="text" email class="form-control" id="email-one"
                 name="forgotPasswordEmail"
                 formControlName="emailControl"
                 placeholder="{{'COMMON.IDENTIFIER' | translate}}"
                 [ngClass]="{ 'is-invalid': emailSubmitted && forgottenPasswordForm.controls.emailControl.errors?.required }">

          <div class="error">
            <div class="form-error-message"
                 *ngIf="emailSubmitted && forgottenPasswordForm.controls.emailControl.errors?.required">
              {{ 'AUTH_WD_008' | translate }}
            </div>

            <div
              [hidden]="!enrsKo && !generalError"
              class="form-error-message">{{ 'WD-ERR-001' | translate }}
            </div>
          </div>
          <div class="info">
            <div
              [hidden]="!emailSubmitted || forgottenPasswordForm.controls.emailControl.errors?.required"
              class="form-info-message"> {{ 'AUTH_WD_021' | translate }}
            </div>
          </div>
        </div>
        <div class="form-group d-flex justify-content-around">
          <button class="btn btn-secondary" id="button_return_forget_password" type="button"
                  (click)="backFromForgotPassword()">{{ 'COMMON.RETURN' | translate }}
          </button>
          <button [disabled]="spinn" class="btn btn-primary" type="submit">
            <span [style.visibility]="spinn ? 'visible' : 'hidden'" class="spinner spinner-inline"></span>
            <span>{{ 'COMMON.VALIDATE' | translate }}</span>
          </button>
        </div>
      </form>
    </div>
    <div class="text-justify" [hidden]="!noRights">
      <h3>{{ 'AUTH_WD_025_1' | translate }}</h3>
      <p>{{ 'AUTH_WD_025_2' | translate }}</p>
      <p>{{ 'AUTH_WD_025_3' | translate }}</p>
      <p>{{ 'AUTH_WD_025_4' | translate }}</p>
    </div>
  </div>
</div>
