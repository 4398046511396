import {Injectable} from '@angular/core';
import {action, computed, observable} from 'mobx';

/**
 * Compute data
 */
@Injectable()
export class ConfigStore {
  @observable backAuthenticator$: string;
  @observable frontDashboard$: string;
  @observable frontDashboardNXPF$: string;
  @observable backDirectory$: string;
  @observable sng$: string;
  @observable carteCPSAuthentUrl$: string;
  @observable idUrl$: number = 0;
  @observable allUrlByStatic$: any;
  @observable regexPassword: string;
  @observable errorMsg$: string;

  constructor() {
  }

  /**
   * TODO To be completed
   */
  @computed get getIdUrl(): number {
    return this.idUrl$;
  }

  /**
   * TODO To be completed
   */
  @computed get getErrorMsg(): string {
    return this.errorMsg$;
  }

  /**
   * TODO To be completed
   */
  @computed get getBackAuthenticator(): string {
    return this.backAuthenticator$;
  }

  /**
   * TODO To be completed
   */
  @computed get getFrontDashboard(): string {
    return this.frontDashboard$;
  }

  /**
   * TODO To be completed
   */
  @computed get getFrontDashboardNXPF(): string {
    return this.frontDashboardNXPF$;
  }

  /**
   * TODO To be completed
   */
  @computed get getBackDirectory(): string {
    return this.backDirectory$;
  }

  /**
   * TODO To be completed
   */
  @computed get getSng(): string {
    return this.sng$;
  }

  /**
   * TODO To be completed
   */
  @computed get getCarteCPSAuthentUrl(): string {
    return this.carteCPSAuthentUrl$;
  }

  @computed get getRegexPassword(): string {
    return this.regexPassword;
  }

  /**
   * TODO To be completed
   */
  @computed get getBackDirectoryPromise(): Promise<any> {
    return new Promise((resolve: any): any => {
      resolve(this.backDirectory$);
    });
  }

  /**
   * TODO To be completed
   */
  @computed get getAllUrlByStatic(): any {
    return this.allUrlByStatic$;
  }

  /**
   * Set url for all back application from back static
   * @param allUrl
   */
  @action setAllUrlByStatic(allUrl: any): void {
    this.allUrlByStatic$ = allUrl;
    this.setBackAuthenticator(this.getAllUrlByStatic.backAuthenticator);
    this.setFrontDashboard(this.getAllUrlByStatic.frontDashboard);
    this.setFrontDashboardNXPF(this.getAllUrlByStatic.frontDashboardNXPF);
    this.setBackDirectory(this.getAllUrlByStatic.backDirectory);
    this.setSng(this.getAllUrlByStatic.sng);
    this.setCarteCPSAuthentUrl(this.getAllUrlByStatic.carteCPSAuthentUrl);
  }

  /**
   * TODO To be completed
   * @param idUrl
   */
  @action setIdUrl(idUrl: number): void {
    this.idUrl$ = idUrl;
  }

  /**
   * TODO To be completed
   * @param errorMsg
   */
  @action setErrorMsg(errorMsg: string): void {
    this.errorMsg$ = errorMsg;
  }

  /**
   * Set back authenticator url
   * @param backAuthenticator
   */
  @action setBackAuthenticator(backAuthenticator: string): void {
    this.backAuthenticator$ = backAuthenticator;
  }

  /**
   * Set front dashboard url
   * @param frontDashboard
   */
  @action setFrontDashboard(frontDashboard: string): void {
    this.frontDashboard$ = frontDashboard;
  }

  /**
   * Set front dashboard url for NXPF tenant
   * @param frontDashboardNXPF
   */
  @action setFrontDashboardNXPF(frontDashboardNXPF: string): void {
    this.frontDashboardNXPF$ = frontDashboardNXPF;
  }

  /**
   * Set back directory url
   * @param backDirectory
   */
  @action setBackDirectory(backDirectory: string): void {
    this.backDirectory$ = backDirectory;
  }

  /**
   * Set sng
   * @param backSng
   */
  @action setSng(backSng: string): void {
    this.sng$ = backSng;
  }

  /**
   * Set CPS Card Auth url
   * @param carteCPSAuthentUrl
   */
  @action setCarteCPSAuthentUrl(carteCPSAuthentUrl: string): void {
    this.carteCPSAuthentUrl$ = carteCPSAuthentUrl;
  }

  /**
   * Set regex password
   * @param regexPassword
   */
  @action setRegexPassword(regexPassword: any): void {
    this.regexPassword = regexPassword;
  }
}
