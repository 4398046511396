var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, computed, observable } from 'mobx';
/**
 * Compute data
 */
var ConfigStore = /** @class */ (function () {
    function ConfigStore() {
        this.idUrl$ = 0;
    }
    Object.defineProperty(ConfigStore.prototype, "getIdUrl", {
        /**
         * TODO To be completed
         */
        get: function () {
            return this.idUrl$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigStore.prototype, "getErrorMsg", {
        /**
         * TODO To be completed
         */
        get: function () {
            return this.errorMsg$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigStore.prototype, "getBackAuthenticator", {
        /**
         * TODO To be completed
         */
        get: function () {
            return this.backAuthenticator$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigStore.prototype, "getFrontDashboard", {
        /**
         * TODO To be completed
         */
        get: function () {
            return this.frontDashboard$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigStore.prototype, "getFrontDashboardNXPF", {
        /**
         * TODO To be completed
         */
        get: function () {
            return this.frontDashboardNXPF$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigStore.prototype, "getBackDirectory", {
        /**
         * TODO To be completed
         */
        get: function () {
            return this.backDirectory$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigStore.prototype, "getSng", {
        /**
         * TODO To be completed
         */
        get: function () {
            return this.sng$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigStore.prototype, "getCarteCPSAuthentUrl", {
        /**
         * TODO To be completed
         */
        get: function () {
            return this.carteCPSAuthentUrl$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigStore.prototype, "getRegexPassword", {
        get: function () {
            return this.regexPassword;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigStore.prototype, "getBackDirectoryPromise", {
        /**
         * TODO To be completed
         */
        get: function () {
            var _this = this;
            return new Promise(function (resolve) {
                resolve(_this.backDirectory$);
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigStore.prototype, "getAllUrlByStatic", {
        /**
         * TODO To be completed
         */
        get: function () {
            return this.allUrlByStatic$;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Set url for all back application from back static
     * @param allUrl
     */
    ConfigStore.prototype.setAllUrlByStatic = function (allUrl) {
        this.allUrlByStatic$ = allUrl;
        this.setBackAuthenticator(this.getAllUrlByStatic.backAuthenticator);
        this.setFrontDashboard(this.getAllUrlByStatic.frontDashboard);
        this.setFrontDashboardNXPF(this.getAllUrlByStatic.frontDashboardNXPF);
        this.setBackDirectory(this.getAllUrlByStatic.backDirectory);
        this.setSng(this.getAllUrlByStatic.sng);
        this.setCarteCPSAuthentUrl(this.getAllUrlByStatic.carteCPSAuthentUrl);
    };
    /**
     * TODO To be completed
     * @param idUrl
     */
    ConfigStore.prototype.setIdUrl = function (idUrl) {
        this.idUrl$ = idUrl;
    };
    /**
     * TODO To be completed
     * @param errorMsg
     */
    ConfigStore.prototype.setErrorMsg = function (errorMsg) {
        this.errorMsg$ = errorMsg;
    };
    /**
     * Set back authenticator url
     * @param backAuthenticator
     */
    ConfigStore.prototype.setBackAuthenticator = function (backAuthenticator) {
        this.backAuthenticator$ = backAuthenticator;
    };
    /**
     * Set front dashboard url
     * @param frontDashboard
     */
    ConfigStore.prototype.setFrontDashboard = function (frontDashboard) {
        this.frontDashboard$ = frontDashboard;
    };
    /**
     * Set front dashboard url for NXPF tenant
     * @param frontDashboardNXPF
     */
    ConfigStore.prototype.setFrontDashboardNXPF = function (frontDashboardNXPF) {
        this.frontDashboardNXPF$ = frontDashboardNXPF;
    };
    /**
     * Set back directory url
     * @param backDirectory
     */
    ConfigStore.prototype.setBackDirectory = function (backDirectory) {
        this.backDirectory$ = backDirectory;
    };
    /**
     * Set sng
     * @param backSng
     */
    ConfigStore.prototype.setSng = function (backSng) {
        this.sng$ = backSng;
    };
    /**
     * Set CPS Card Auth url
     * @param carteCPSAuthentUrl
     */
    ConfigStore.prototype.setCarteCPSAuthentUrl = function (carteCPSAuthentUrl) {
        this.carteCPSAuthentUrl$ = carteCPSAuthentUrl;
    };
    /**
     * Set regex password
     * @param regexPassword
     */
    ConfigStore.prototype.setRegexPassword = function (regexPassword) {
        this.regexPassword = regexPassword;
    };
    __decorate([
        observable,
        __metadata("design:type", String)
    ], ConfigStore.prototype, "backAuthenticator$", void 0);
    __decorate([
        observable,
        __metadata("design:type", String)
    ], ConfigStore.prototype, "frontDashboard$", void 0);
    __decorate([
        observable,
        __metadata("design:type", String)
    ], ConfigStore.prototype, "frontDashboardNXPF$", void 0);
    __decorate([
        observable,
        __metadata("design:type", String)
    ], ConfigStore.prototype, "backDirectory$", void 0);
    __decorate([
        observable,
        __metadata("design:type", String)
    ], ConfigStore.prototype, "sng$", void 0);
    __decorate([
        observable,
        __metadata("design:type", String)
    ], ConfigStore.prototype, "carteCPSAuthentUrl$", void 0);
    __decorate([
        observable,
        __metadata("design:type", Number)
    ], ConfigStore.prototype, "idUrl$", void 0);
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], ConfigStore.prototype, "allUrlByStatic$", void 0);
    __decorate([
        observable,
        __metadata("design:type", String)
    ], ConfigStore.prototype, "regexPassword", void 0);
    __decorate([
        observable,
        __metadata("design:type", String)
    ], ConfigStore.prototype, "errorMsg$", void 0);
    __decorate([
        computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], ConfigStore.prototype, "getIdUrl", null);
    __decorate([
        computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], ConfigStore.prototype, "getErrorMsg", null);
    __decorate([
        computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], ConfigStore.prototype, "getBackAuthenticator", null);
    __decorate([
        computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], ConfigStore.prototype, "getFrontDashboard", null);
    __decorate([
        computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], ConfigStore.prototype, "getFrontDashboardNXPF", null);
    __decorate([
        computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], ConfigStore.prototype, "getBackDirectory", null);
    __decorate([
        computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], ConfigStore.prototype, "getSng", null);
    __decorate([
        computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], ConfigStore.prototype, "getCarteCPSAuthentUrl", null);
    __decorate([
        computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], ConfigStore.prototype, "getRegexPassword", null);
    __decorate([
        computed,
        __metadata("design:type", Promise),
        __metadata("design:paramtypes", [])
    ], ConfigStore.prototype, "getBackDirectoryPromise", null);
    __decorate([
        computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], ConfigStore.prototype, "getAllUrlByStatic", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ConfigStore.prototype, "setAllUrlByStatic", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", void 0)
    ], ConfigStore.prototype, "setIdUrl", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], ConfigStore.prototype, "setErrorMsg", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], ConfigStore.prototype, "setBackAuthenticator", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], ConfigStore.prototype, "setFrontDashboard", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], ConfigStore.prototype, "setFrontDashboardNXPF", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], ConfigStore.prototype, "setBackDirectory", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], ConfigStore.prototype, "setSng", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], ConfigStore.prototype, "setCarteCPSAuthentUrl", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ConfigStore.prototype, "setRegexPassword", null);
    return ConfigStore;
}());
export { ConfigStore };
