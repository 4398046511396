import { Injectable } from '@angular/core';
import { ErrorModel } from '../../models/error.model';

/**
 * Error component - displayed if static config is unreachable
 */
@Injectable()
export class ErrorService {
  private _error: ErrorModel;

  constructor() { }

  get error(): ErrorModel {
    return this._error;
  }
  set error(error: ErrorModel) {
    this._error = error;
  }
}
