import {Injectable} from '@angular/core';
import {Api} from '../api/api.service';
import 'rxjs/add/operator/map';
import {Observable} from '../../../../node_modules/rxjs';

/**
 * Login Service
 */
@Injectable()
export class LoginService {

  // List of ENRS API error code
  public static readonly ENRS_CODE_INVALID_PARAMETER: string = 'ENRS_CODE_INVALID_PARAMETER';
  public static readonly ENRS_CODE_WRONG_IDENTIFIER: string = 'ENRS_CODE_WRONG_IDENTIFIER';
  public static readonly ENRS_CODE_DUPLICATE_USER_EMAIL: number = 2209;
  public static readonly ENRS_CODE_DUPLICATE_USER_NATIONALID: number = 2210;
  public static readonly ENRS_CODE_UNKNOW_TYPE: string = 'ENRS_CODE_UNKNOW_TYPE';
  public static readonly ENRS_CODE_PASSWORD_COMPLEXITY: number = 2211;
  public static readonly ENRS_CODE_MISSING_PARAMETER: string = 'ENRS_CODE_MISSING_PARAMETER';
  public static readonly ENRS_CODE_TECHNIQUE: string = 'ENRS_CODE_TECHNIQUE';
  public static readonly ENRS_CODE_INVALID_EMAIL: string = 'ENRS_CODE_INVALID_EMAIL';
  public static readonly ENRS_CODE_EMPTY_EMAIL: string = 'ENRS_CODE_EMPTY_EMAIL';
  public static readonly ENRS_CODE_PASSWORD_MATCH: string = 'ENRS_CODE_PASSWORD_MATCH';

  // List of register intern error code
  public static readonly API_CODE_CAPTCHA_ERROR: number = 1003;

  constructor(private api: Api) {
  }

  /**
   * TODO To be completed
   */
  get(): Observable<any> {
    return this.api.get('/user/login')
      .map((response: any) => response);
  }

  /**
   * TODO To be completed
   * @param data
   */
  postLogin(data: any): Observable<any> {
    return this.api.postFullResponse('/user/login', data).map((response: any) => response);
  }

  /**
   * Reset password
   * @param data
   */
  getResetPassword(data: any): Observable<any> {
    return this.api.get('/user/resetpassword?login=' + data).map((response: any) => response);
  }

  /**
   * Call API to register a user
   * @param data
   */
  postRegister(data: any): Observable<any> {
    return this.api.post('/user/register', data)
      .map((response: any) => response);
  }

  /**
   * Call API to register a user
   * @param data
   * @param authorization
   */
  searchUserFromIdentityAccessManager(data: any, authorization: string): Observable<any> {
    return this.api.searchUserFromIds('/person/search', data, authorization)
      .map((response: any) => response);
  }

  /**
   * Call API to validate pin code
   * @param data
   */
  postValidateRegistrationPinCode(data: any): Observable<any> {
    return this.api.post('/user/register/validatepincode', data).map((response: any) => response);
  }
}
