import { Component, OnInit } from '@angular/core';
import { ErrorService } from '../../services/error/error.service';
import { ErrorModel } from '../../models/error.model';

/**
 * Error component - displayed if static config is unreachable
 */
@Component({
  selector: 'app-error',
  styleUrls: ['./error.component.scss'],
  templateUrl: 'error.component.html'
})
export class ErrorComponent implements OnInit {
  public error: ErrorModel;
  constructor(
    private errorService: ErrorService
  ) { }

  /**
   * Init function
   * @description : Init function of ErrorComponent
   */
  public ngOnInit(): void {
    this.error = this.errorService.error;
    if (this.error == null) {
      this.error = { code: 404, content: 'error.notFound' };
    }

    console.log('init is over !');
  }
}
