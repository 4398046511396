<div>
  <h2 id="headingRegistration">Professionnel de structure ? <span class="expand blue" data-toggle="collapse"
                                                                  data-target="#collapseRegistration"
                                                                  aria-expanded="true"
                                                                  aria-controls="collapseRegistration">Je crée mon compte</span>
  </h2>
  <div id="collapseRegistration" class="formContainer collapse" aria-labelledby="headingRegistration"
       data-parent="#accordion">
    <form (ngSubmit)="registerNewAccount(registrationForm)" #registrationForm="ngForm" [hidden]="accountRegister">
      <div id="titleContainer" class="form-group form-inline justify-content-between">
        <label class="light-grey">{{ 'COMMON.CIVILITY' | translate }}</label>

        <div class="pretty p-default">
          <input #civilityRadio="ngModel" class="" required id="civility.dr" type='radio'
                 value='Dr'
                 name='civility' [(ngModel)]="user.civility"/>
          <div class="state p-primary-o">
            <label for="civility.dr">{{ 'COMMON.DR' | translate }}</label>
          </div>
        </div>
        <div class="pretty p-default">
          <input #civilityRadio="ngModel" class="" required id="civility.pr" type='radio' value='Pr' name='civility'
                 [(ngModel)]="user.civility"/>
          <div class="state p-primary-o">
            <label for="civility.pr">{{ 'COMMON.PR' | translate }}</label>
          </div>
        </div>
        <div class="pretty p-default">
          <input #civilityRadio="ngModel" class="" required id="civility.ms" type='radio' value='Mme' name='civility'
                 [(ngModel)]="user.civility"/>
          <div class="state p-primary-o">
            <label for="civility.ms">{{ 'COMMON.MME' | translate }}</label>
          </div>
        </div>
        <div class="pretty p-default">
          <input #civilityRadio="ngModel" class="" required id="civility.m" type='radio' value='M' name='civility'
                 [(ngModel)]="user.civility"/>
          <div class="state p-primary-o">
            <label for="civility.m">{{ 'COMMON.M' | translate }}</label>
          </div>
        </div>
      </div>
      <div class="error">
        <div
          [hidden]="(!accountSubmitted && (civilityRadio.valid || civilityRadio.pristine)) || (accountSubmitted && civilityRadio.valid)"
          class="form-error-message">{{ 'AUTH_WD_008' | translate }}
        </div>
      </div>
      <div class="form-group">
        <input #identifierInput="ngModel" id="identifier" required minlength="5" type="text" class="form-control"
               name='identifier' pattern="^[a-zA-Z0-9._-]*"
               [(ngModel)]="user.userName" placeholder="{{'COMMON.IDENTIFIER' | translate}}"/>
        <div class="error">
          <div
            [hidden]="(!accountSubmitted && (identifierInput.valid || identifierInput.pristine || identifierInput.untouched)) || (accountSubmitted && (identifierInput.valid || identifierInput.errors?.pattern))"
            class="form-error-message">{{ 'AUTH_WD_032' | translate }}
          </div>
          <div [hidden]="!identifierInput.errors?.pattern" class="form-error-message">{{ 'AUTH_WD_027' | translate }}
          </div>
          <div [hidden]="registerErrorKey !== 'AUTH_WD_004'" class="form-error-message">
            {{ registerErrorMessage }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <input #emailInput="ngModel" validateOnBlur id="emailRegistration" autocomplete="email" type="email" required
               placeholder="{{'COMMON.EMAIL' | translate}}" class="form-control" name='email' email
               [(ngModel)]="user.email" (ngModelChange)="resetRegisterError()"/>
        <div class="error">
          <div [hidden]="!(accountSubmitted && (emailInput.pristine || emailInput.untouched || user.email === ''))"
               class="form-error-message">{{ 'AUTH_WD_011' | translate }}
          </div>
          <div [hidden]="registerErrorKey !== 'AUTH_WD_002'" class="form-error-message">
            {{ registerErrorMessage }}
          </div>
          <div
            [hidden]="(user.email === '' || emailInput.valid || emailInput.pristine || emailInput.untouched) || (emailInput.valid)"
            class="form-error-message">
            {{ 'AUTH_WD_028' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <input #nameInput="ngModel" id="name" required type='text' class="form-control" name='name'
               [(ngModel)]="user.lastName" placeholder="{{'COMMON.NAME' | translate}}"/>
        <div class="error">
          <div
            [hidden]="(!accountSubmitted && (nameInput.valid || nameInput.pristine || nameInput.untouched)) || (accountSubmitted && nameInput.valid)"
            class="form-error-message">{{ 'AUTH_WD_008' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <input #firstnameInput="ngModel" id="firstname" required type='text' class="form-control" name='firstname'
               [(ngModel)]="user.firstName" placeholder="{{'COMMON.FIRSTNAME' | translate}}"/>
        <div class="error">
          <div
            [hidden]="(!accountSubmitted && (firstnameInput.valid || firstnameInput.pristine || firstnameInput.untouched)) || (accountSubmitted && firstnameInput.valid)"
            class="form-error-message">{{ 'AUTH_WD_008' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <input
          #passwordInput="ngModel"
          id="password"
          required type='password'
          class="form-control"
          name='password'
          [(ngModel)]="user.password"
          autocomplete="current-password"
          (keyup)="testPassword()"
          placeholder="{{'COMMON.PASSWORD' | translate}}"
        />
        <div class="error">
          <div
            [hidden]="(!accountSubmitted && (passwordInput.valid || passwordInput.pristine || passwordInput.untouched)) || (accountSubmitted && passwordInput.valid)"
            class="form-error-message">{{ 'AUTH_WD_008' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <input #passwordConfirmationInput="ngModel" id="passwordConfirmation" required type='password'
               class="form-control" name="passwordConfirmation" [(ngModel)]="user.passwordConfirmation"
               autocomplete="off" placeholder="{{'COMMON.PASSWORDCONFIRM' | translate}}"/>
        <div class="error">
          <div
            [hidden]="(!accountSubmitted && (passwordConfirmationInput.valid || passwordConfirmationInput.pristine || passwordConfirmationInput.untouched)) || (accountSubmitted && passwordConfirmationInput.valid)"
            class="form-error-message">{{ 'AUTH_WD_008' | translate }}
          </div>
          <div
            [hidden]="passwordConfirmationInput.value === passwordInput.value || (passwordConfirmationInput.value.length === 0) || passwordConfirmationInput.pristine"
            class="form-error-message">{{ 'AUTH_WD_012' | translate }}
          </div>
        </div>
        <div class="error-long">
          <div [hidden]="registerErrorKey !== 'AUTH_WD_006'" class="form-error-message">
            {{ 'AUTH_WD_006' | translate }}
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="light-grey">Réécrire les caractères</label>

        <div class="captchaContainer d-flex justify-content-start">
          <input #captchaInput="ngModel" id="captcha" required type="text" name="captcha"
                 [(ngModel)]="user.captcha"/>
        </div>
        <div class="error">
          <div
            [hidden]="(!accountSubmitted && (captchaInput.valid || captchaInput.pristine || captchaInput.untouched)) || (accountSubmitted && captchaInput.valid)"
            class="form-error-message">{{ 'AUTH_WD_008' | translate }}
          </div>
          <div [hidden]="registerErrorKey !== 'AUTH_WD_018'" class="form-error-message">
            {{ registerErrorMessage }}
          </div>
        </div>
      </div>
      <div class="form-group text-center">
        <button class="btn btn-primary" [disabled]="spinn">
          <span [style.visibility]="spinn ? 'visible' : 'hidden'" class="spinner spinner-inline"></span>
          <span>Créer mon compte</span>
        </button>
      </div>
      <div class="form-group">
        <div class="pretty p-default">
          <input #termOfUseCheckbox="ngModel" class="" type='checkbox' name="termOfUse" required [(ngModel)]="termOfUse"/>
          <div class="state p-primary-o">
            <label style="width: 0;"></label>
          </div>
        </div>
        <span [innerHTML]="'AUTH.REGISTER.TERMOFUSE' | translate"></span>
        <div class="error">
          <div [hidden]="!accountSubmitted || (accountSubmitted && termOfUseCheckbox.valid)" class="form-error-message">
            {{'AUTH_WD_008' | translate}}
          </div>
          <div [hidden]="registerErrorKey !== 'AUTH_WD_009'" class="col-md form-error-message">
            {{ registerErrorMessage }}
          </div>
        </div>
      </div>
    </form>
    <div [hidden]="!accountRegister" class="text-justify registration-confirmation">
      <h3>{{ 'AUTH_WD_013_1' | translate }}</h3>
      <p [innerHTML]="'AUTH_WD_013_2' | translate"></p>
    </div>
  </div>
</div>
