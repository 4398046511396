<div class="container">
  <div id="leftSide">
    <div id="TLCinfo">
      <p class="strong">{{ 'PATIENT_MSG.TITLE' | translate }}</p>
      <p>{{ 'PATIENT_MSG.TEXT' | translate }}</p>
      <a href="https://formation.sesan.fr/ortif/exercices/teleconsultation-directe-smartphone-patient/#carouselExo" target="_blank" class="btn btn-light">{{ 'PATIENT_MSG.LINK' | translate }}</a>
    </div>
  </div>
  <div id="sectionMain">
    <app-header></app-header>
    <router-outlet></router-outlet>
  </div>
</div>
