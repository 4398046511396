import {Component, OnInit, Renderer2} from '@angular/core';
import { AppReadyEvent } from '../../events';
import {Meta} from '@angular/platform-browser';

/**
 * ORTIF layout
 */
@Component({
  selector: 'app-ortif-layout',
  templateUrl: './ortif-layout.component.html',
  styleUrls: ['./ortif-layout.component.scss']
})
export class OrtifLayoutComponent implements OnInit {

  constructor(
      private appReadyEvent: AppReadyEvent,
      private renderer: Renderer2,
      private meta: Meta
  ) { }

  /**
   * To be completed
   */
  ngOnInit(): any {
    this.renderer.setAttribute(document.body, 'class', 'ortif');
    this.appReadyEvent.trigger();
    this.meta.addTags([
      { name: 'description', content: 'ORTIF' },
      { name: 'apple-itunes-app', content: 'app-id=1255452178' },
      { name: 'google-play-app', content: 'app-id=com.parsys.ortif' },
    ]);
  }

}
